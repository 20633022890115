<!--商家设置-菜品管理-->
<template>
    <div class="food_admin_page">
        <el-form>
            <el-form-item>
                <el-button type="primary" size="mini" @click="showAddDialog('add')">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table size="mini" border style="width: 100%;" :data="dataObj.list" :loading="postdata.loading">
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column prop="Name" label="菜品名称" align="center"></el-table-column>
            <el-table-column prop="Price" label="价格" align="center">
                <template slot-scope="{ row }">
                    ￥{{ row.Price }}
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center">
                <template slot-scope="{ row }">
                    <el-button type="text" size="mini" @click="showAddDialog('edit', row)">编辑</el-button>
                    <el-button type="text" size="mini" @click="deleteObj(row.Id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="page_box">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="postdata.page"
            :page-sizes="[20, 40, 100]"
            :page-size="postdata.perPage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dataObj.total">
            </el-pagination>
        </div>

        <!-- 新增弹窗 beginning -->
        <el-dialog
            :class="'add_message_dialog ' + addPostdata.showType" :visible="addPostdata.show"
            :show-close="false"
            :title="addPostdata.showType === 'edit' ? '编辑' : '新增'"
            width="500px"
        >
            <el-form label-width="80px" :model="addPostdata" ref="addPostdata" :rules="rulesCustom">
                <el-form-item label="菜品名称" prop="name">
                    <el-input v-model.trim="addPostdata.name" type="text" placeholder="请输入菜品名称"></el-input>
                </el-form-item>
                <el-form-item label="价格" prop="price">
                    <el-input-number v-model="addPostdata.price" :precision="2" :step="0.1" :max="99999" controls-position="right"></el-input-number>
                </el-form-item>
                <el-form-item label="图片" prop="picture">
                    <el-upload
                        class="avatar-uploader"
                        action="/uploadToOss"
                        :headers="{Auth: token}"
                        :show-file-list="false"
                        accept="image/*"
                        :on-success="handleAvatarSuccess">
                        <img v-if="addPostdata.picture" :src="addPostdata.picture" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div class="add_dialog_footer" slot="footer">
                <el-button type="default" plain @click="closeDialog('addPostdata')" size="mini">取消</el-button>
                <el-button type="primary" @click="handleSubmit('addPostdata')" :loading="addPostdata.loading" size="mini">确定</el-button>
            </div>
        </el-dialog>
        <!-- 新增弹窗 end -->
    </div>
</template>
<script>
import { stg } from '@/util/index'

export default {
    data() {
        return {
            token: '', // token
            postdata: {
                loading: false, // 列表数据加载状态
                StartAt: 0,
                Size: 10,
                page: 1,
                perPage: 10
            },
            // 列表数据
            dataObj: {
                list: [],
                pages: 0,
                total: 0
            },
            // 新增消息入参
            addPostdata: {
                loading: false, // 确定按钮加载状态
                show: false, // 是否显示新增弹窗
                showType: 'add', // 显示类型 add添加；look查看
                name: '', // 名称
                price: null, // 价格
                picture: '' // 图片
            },
            rulesCustom: {
                name: [
                    { required: true, message: '请输入菜品名称', trigger: 'change' }
                ],
                price: [
                    { required: true, message: '请输入价格', trigger: 'change' },
                    { type: 'number', min: 0, message: '价格不小于0', trigger: 'change' }
                ],
                picture: [
                    { required: true, message: '请上传图片', trigger: 'change' }
                ]
            }
        }
    },
    mounted() {
        this.token = stg().getItem('token')
        this.netGetMessageList() // 获取消息列表
    },
    methods: {
        /**
         * @description 上传图片成功
         */
        handleAvatarSuccess(res, file) {
            this.addPostdata.picture = res.Data
        },
        /**
         * @description 新增消息
         * @param {String} 表单名称
         */
        handleSubmit(name) {
            this.$refs[name].validate(valid => {
                if (valid) {
                    this.addPostdata.loading = true
                    const addPostdata = JSON.parse(JSON.stringify(this.addPostdata))
                    addPostdata.price = addPostdata.price.toString()
                    this.$post('admin/addCustomerCuisine', addPostdata).then(Msg => {
                        this.$message({
                            message: Msg || '操作成功',
                            type: 'success'
                        })
                        // 刷新数据
                        this.postdata.page = 1
                        this.netGetMessageList()

                        // 关闭弹窗
                        this.addPostdata.show = false
                        this.addPostdata.loading = false
                    })
                    .catch(() => {
                        this.addPostdata.loading = false
                    })
                }
            })
        },
        /**
         * @description 重置表单数据
         */
        resetForm(name) {
            this.$refs[name].resetFields()
        },
        /**
         * @description 关闭弹窗
         * @param {String} name 数据对象名称
         */
        closeDialog(name) {
            this[name].show = false
            this.resetForm(name)
        },
        /**
         * @description 显示弹窗
         * @param {String} type 显示类型 add添加；look查看
         */
        showAddDialog(type, data) {
            this.addPostdata.show = true
            this.addPostdata.showType = type

            if (data) {
                this.addPostdata = {
                    ...this.addPostdata,
                    id: data.Id,
                    name: data.Name,
                    price: Number(data.Price),
                    picture: data.Picture
                }
            }
        },
        /**
         * @description 获取消息列表
         */
        netGetMessageList() {
            this.postdata.loading = true
            this.postdata.StartAt = this.postdata.perPage * (this.postdata.page - 1)
            this.postdata.Size = this.postdata.perPage
            this.$post('admin/listCustomerCuisine', this.postdata).then(res => {
                this.dataObj.list = res.content
                this.dataObj.pages = Math.ceil(res.total / this.postdata.perPage)
                this.dataObj.total = res.total
                this.postdata.loading = false
            })
            .catch(() => {
                this.postdata.loading = false
            })
        },
        /**
         * @description 查询
         */
        searchData() {
            this.postdata.page = 1
            this.netGetMessageList()
        },
        /**
         * @description 删除菜品
         * @param {Number} 菜品id
         */
        deleteObj(id) {
            this.$confirm('确定删除该菜品？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$post('admin/deleteCustomerCuisine', {
                        id: id
                    }
                ).then(res => {
                    this.$message({
                        type: 'success',
                        message: '删除成功！'
                    })
                    this.netGetMessageList()
                }).catch(() => {
                    this.$message({
                        type: 'error',
                        message: '删除失败!'
                    })
                })
            })
        },

        /**
         * @description 每页条数
         */
        handleSizeChange(pageSize) {
            this.postdata.page = 1
            this.postdata.perPage = pageSize
            this.netGetMessageList()
        },

        /**
         * @description 页码
         */
        handleCurrentChange(page) {
            this.postdata.page = page
            this.netGetMessageList()
        }
    }
}
</script>
<style lang="scss">
.food_admin_page {
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
    }
    .avatar {
        width: 120px;
        height: 120px;
        display: block;
    }

    .page_box {
        padding: 30px 0;
        text-align: center;
    }

    .add_message_dialog {
        &.look {
            .form_item_ueditor {
                .el-form-item__content {
                    padding: 10px 0;
                }
            }
        }
    }
}
</style>
